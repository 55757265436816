import React, { useState } from "react"
import {MdWeb, MdDeveloperMode, MdSend, MdBrush, MdCloudQueue, MdLayers} from "react-icons/md"
import {FaUserTie} from "react-icons/fa"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import svg from '../images/layered-waves-haikei.svg'
import Seo from "../components/seo"
import {Container, Row, Col, Button, Card, Form, Alert} from "react-bootstrap";
import {netlifyFormHandle} from "../utils/form";

const IndexPage = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const handleSignUp = (e) => {
    setLoading(true);
    return netlifyFormHandle(e)
      .then(() => {
        setSuccess(true);
      });
  };
  return (
    <Layout>
      <Seo title="Euroweb B.V. - Ambitious Technology Professionals" />
      <section className="bg-light pt-3 pb-5">
        <Container>
          <Row className="align-items-center">
            <Col xs="12" md="6">
              <h1 className="font-weight-bold">
                Ambitious Technology Professionals
              </h1>
              <p className="mb-5">
                We are advisors, engineers, and designers solving business challenges with innovative technology solutions
              </p>
              <Button size="lg" variant="danger" as="a" href="/#contact">Contact Us</Button>
            </Col>
            <Col xs="12" md="6" className="d-flex justify-content-center pt-5">
              <StaticImage
                src="../images/software.png"
                quality={95}
                placeholder="svgPath"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Euroweb B.V"
              />
            </Col>
          </Row>
        </Container>
      </section>
      <section id="services" className="py-5">
        <Container>
          <h2 className="text-center pb-5 font-weight-bold">Our Services</h2>
          <Row className="text-center mb-3">
            <Col xs="12" md="6" className="d-flex align-items-center flex-column">
              <h4><span className="underline-magical">Engineering</span></h4>
              <Row className="pt-3 w-75">
                <Col xs="12" className="d-flex mb-3 py-3 px-4 bg-light align-items-center rounded-pill">
                  <div className="display-4 text-danger d-flex align-items-center mr-3"><MdWeb /></div>
                  <h5>Web Development</h5>
                </Col>
                <Col xs="12" className="d-flex mb-3 py-3 px-4 bg-light align-items-center rounded-pill">
                  <div className="display-4 text-danger d-flex align-items-center mr-3"><MdDeveloperMode /></div>
                  <h5>Mobile Development</h5>
                </Col>
                <Col xs="12" className="d-flex mb-3 py-3 px-4 bg-light align-items-center rounded-pill">
                  <div className="display-4 text-danger d-flex align-items-center mr-3"><MdCloudQueue /></div>
                  <h5>DevOps</h5>
                </Col>
              </Row>
            </Col>
            <Col xs="12" md="6" className="d-flex align-items-center flex-column">
              <h4><span className="underline-magical">Advisory</span></h4>
              <Row className="pt-3 w-75">
                <Col xs="12" className="mb-3 d-flex py-3 px-4 bg-light align-items-center rounded-pill">
                  <div className="display-4 d-flex align-items-center mr-3 text-danger"><MdLayers /></div>
                  <h5>Software Architecture</h5>
                </Col>
                <Col xs="12" className="mb-3 d-flex py-3 px-4 bg-light align-items-center rounded-pill">
                  <div className="display-4 d-flex align-items-center mr-3 text-danger"><MdBrush /></div>
                  <h5>Product Design</h5>
                </Col>
                <Col xs="12" className="mb-3 d-flex py-3 px-4 bg-light align-items-center rounded-pill">
                  <div className="display-4 d-flex align-items-center mr-3 text-danger"><FaUserTie /></div>
                  <h5>CTO as a Service</h5>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="contact" className="pt-3" style={{
        backgroundImage: `url(${svg})`,
        backgroundPosition: 'center bottom',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      }}>
        <Container>
          <Row className="justify-content-center">
            <Col xs="12" md="6">
              <Card border="light" className="shadow">
                <Card.Body>
                  <Card.Title>Contact Us</Card.Title>
                  <Card.Text>
                    {success ? (
                      <Alert variant="success" className="my-5">
                        <Alert.Heading>Thank you!</Alert.Heading>
                        <p>
                          Your message has been successfully sent. We will get back to you shortly.
                        </p>
                      </Alert>
                    ) : (
                      <Form onSubmit={handleSignUp} action="/" method="POST" data-netlify="true" name="contact">
                        <input type="hidden" value="contact" name="form-name"/>
                        <Row>
                          <Col>
                            <Form.Group>
                              <Form.Label>Name</Form.Label>
                              <Form.Control placeholder="eg. John Doe" required name="name" />
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group>
                              <Form.Label>Email</Form.Label>
                              <Form.Control placeholder="eg. john@example.com" required type="email" name="email" />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Form.Group>
                              <Form.Label>Phone</Form.Label>
                              <Form.Control placeholder="+123456789" name="phone" />
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group>
                              <Form.Label>Subject</Form.Label>
                              <Form.Control placeholder="eg. Mobile development" required name="subject" />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Form.Group>
                              <Form.Label>Message</Form.Label>
                              <Form.Control as="textarea" rows={5} required name="message" />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="mt-3 text-right">
                          <Col>
                            <Button size="lg" disabled={loading} type="submit">
                              {loading ? (
                                <>
                                  <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"/>
                                  Loading...
                                </>
                              ) : (
                                <>
                                  <MdSend />&nbsp;Send
                                </>
                              )}
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default IndexPage
